<template>
  <div
    style="background-image: url(img/bg-login.jpg)"
    class="absolute inset-0 bg-no-repeat bg-cover flex items-center justify-items-center px-4"
  >
    <div class="bg-white w-full p-10 rounded-t-md shadow-md md:max-w-lg md:mx-auto relative">
      <button
        type="button"
        class="rounded-full bg-white h-12 w-12 focus:outline-none focus:ring absolute -left-20 top-2"
        @click="$router.push({ name: 'login' })"
      >
        <i class="tmlps-i-arrow-left text-3xl h-8 w-8"></i>
      </button>
      <div>
        <img class="w-56 mx-auto" src="../assets/logo.png" alt="Timelapse México" />
      </div>

      <h2 class="mt-8 font-semibold text-2xl text-center">Recuperar contraseña</h2>
      <p class="my-4 text-lg text-left">
        Te enviaremos un link por correo electrónico <br />
        donde podrás establecer una nueva contraseña
      </p>

      <form @submit.prevent="forgotPassword" class="mt-4">
        <div class="mt-3">
          <EmailInput v-model="email" :error="errors.first('email')" />
        </div>

        <div class="mt-4 text-sm text-red-600 text-center" :show="errors.has('forgot-password')">
          {{ errors.first('forgot-password') }}
        </div>

        <div class="mt-4 flex items-center justify-center">
          <button
            type="submit"
            class="bg-primary text-white text-sm px-12 py-3 w-1/2 text-center rounded-md transition ease-in-out duration-150 hover:bg-primary-400 focus:outline-none focus:ring-4"
            :class="{ 'cursor-not-allowed': loading }"
            :disabled="loading"
          >
            <svg
              v-if="loading"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Errors from '@/util/Errors';
import EmailInput from '@/components/EmailInput';

export default {
  name: 'forgot-password',
  components: { EmailInput },
  data() {
    return {
      email: '',
      loading: false,
      errors: new Errors(),
    };
  },

  methods: {
    async forgotPassword() {
      this.loading = true;
      this.errors.clear();

      try {
        const credentials = {
          email: this.email,
        };

        await store.dispatch('auth/forgotPassword', credentials);

        this.$router.replace({ name: 'login' });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
        }
        this.loading = false;
      }
    },
  },
};
</script>
